import { Link, useParams } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import questoesAnsiedade from '../../data/QuizAnsiedadeData';
import questoesDepressao from '../../data/QuizDepressaoData';

import livroAMenteVencendo from '../../img/livro/livro-a-mente-vencendo.jpg'
import livroAsCoisas from '../../img/livro/livro-as-coisas-que-voce.jpg'
import LivroSugerido from '../../components/LivroSugerido/LivroSugerido';

export default function Quiz() {
    let { condicao } = useParams();
    const [condicaoSelecionada, setCondicaoSelecionada] = useState([])
    let [idQuestaoAtual, setIdQuestaoAtual] = useState(1)
    const [questaoAtual, setQuestaoAtual] = useState('')
    const [iniciouQuiz, setIniciouQuiz] = useState(false)
    const [finalQuiz, setFinalQuiz] = useState(false)
    const [somaValores, setSomaValores] = useState(0);
    const [transicao, setTransicao] = useState(false)


    useEffect(() => {
        if (condicao == 'Ansiedade') {
            setCondicaoSelecionada(questoesAnsiedade)
            setQuestaoAtual(questoesAnsiedade.find((item) => item.Id == idQuestaoAtual).Questao)
        }
        else if (condicao == 'Depressão') {
            setCondicaoSelecionada(questoesDepressao)
            setQuestaoAtual(questoesDepressao.find((item) => item.Id == idQuestaoAtual).Questao)
        }

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, []);

    function handleIniciar() {
        setIniciouQuiz(true);
    }

    function handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        let formData = new FormData(form)

        var opcao = formData.get('opcao');

        document.querySelector('#texto-validacao').textContent = ""
        if (!opcao) {
            document.querySelector('#texto-validacao').textContent = "Selecione uma opção"
            return;
        }

        let valorOpcaoSelecionada = parseInt(formData.get('opcao'));
        var resultado = somaValores + valorOpcaoSelecionada;

        setSomaValores(resultado);

        if (idQuestaoAtual < condicaoSelecionada.length) {
            setIdQuestaoAtual(++idQuestaoAtual);
            setQuestaoAtual(condicaoSelecionada.find((item) => item.Id == idQuestaoAtual).Questao);
        }

        if (idQuestaoAtual == condicaoSelecionada.length) {
            setFinalQuiz(true);
        }

        setTransicao(true);

        form.reset();

        setTimeout(() => {
            setTransicao(false);
        }, 100)
    }

    return (
        <>
            <div className="min-vh-100 d-flex flex-column">
                <Navbar classNameTitulo="text-danger-emphasis" classNameItem="text-danger-emphasis" />
                {condicaoSelecionada && (condicao == 'Ansiedade' || condicao == 'Depressão') &&
                    <>
                        <div className='d-flex flex-column justify-content-center align-items-center flex-fill'>
                            <div className='col-11 col-sm-9 col-md-8 col-lg-6 col-xxl-5 text-secondary-emphasis'>
                                {!iniciouQuiz &&
                                    <FadeIn transitionDuration='500'>
                                        <div className="fs-5" >
                                            <p className='fw-bold'>Esse quiz não substitui a consulta com o profissional e tambem não é um diagnóstico. Ao fim do questionário, serão informados os escores e sua interpretação. De acordo com a pontuação, poderá haver os seguintes resultados:</p>                                            
                                                <>
                                                    <p className='fw-medium fst-italic my-0'>a) Ausência de sintomas significativos de {condicao.toLowerCase()}</p>
                                                    <p className='fw-medium fst-italic mt-0'>b) Presença de sintomas significativos de {condicao.toLowerCase()}</p>
                                                </>
                                            <div className='d-flex'>
                                                <button className='btn btn-primary ms-auto w-100 mt-2' onClick={handleIniciar}>Iniciar</button>
                                            </div>
                                        </div>
                                    </FadeIn>
                                }
                                {iniciouQuiz && !finalQuiz && !transicao ?
                                    <>
                                        <FadeIn transitionDuration='500'>
                                            <p className='fs-5 fw-medium fst-italic  text-dark-emphasis mb-2'>Durante as últimas duas semanas, com que frequência você foi incomodado(a) pelos problemas a seguir?</p>
                                            <p className='fs-5 fw-bold text-secondary-emphasis mb-1 '>{questaoAtual}</p>
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className='d-flex flex-column fs-5'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value={0} name="opcao" id="opcao1" />
                                                        <label className="form-check-label" htmlFor="opcao1">
                                                            Nenhuma vez
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value={1} name="opcao" id="opcao2" />
                                                        <label className="form-check-label" htmlFor="opcao2">
                                                            Vários dias
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value={2} name="opcao" id="opcao3" />
                                                        <label className="form-check-label" htmlFor="opcao3">
                                                            Maioria dos dias
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value={3} name="opcao" id="opcao4" />
                                                        <label className="form-check-label" htmlFor="opcao4">
                                                            Sempre
                                                        </label>
                                                    </div>
                                                    <p className='fs-6 text-danger fw-bold my-0 ms-1' id="texto-validacao"></p>
                                                    <button className='btn btn-primary mt-2'>Avançar</button>
                                                </div>
                                            </form>
                                        </FadeIn>
                                    </>
                                    : ''
                                }
                                {finalQuiz && condicao == 'Ansiedade' && somaValores <= 8 &&
                                    <FadeIn transitionDuration='500'>
                                        <div className='fw-medium text-secondary-emphasis fs-5 mt-3 mt-sm-0'>
                                            <p className='fs-4 mb-1 fw-bold'>Sua somatória: {somaValores}</p>
                                            <p className='my-0'>Ausência de sintomas significativos de Ansiedade.</p>
                                            <p className='my-0'>Mas caso deseje aprender a lidar com os sintomas relatados, não deixe de buscar um profissional.</p>
                                            <p className='mt-0 mb-3 text-center'><Link to="/profissionais">Conheça os profissionais do projeto</Link></p>
                                        </div>
                                    </FadeIn>
                                }                                
                                {finalQuiz && condicao == "Ansiedade" && somaValores >= 9 &&
                                    <>
                                        <FadeIn transitionDuration='500'>
                                            <div className='fw-medium text-secondary-emphasis fs-5 mt-3 mt-sm-0'>
                                                <p className='fw-bold fs-4 mb-1'>Sua somatória: {somaValores}</p>
                                                <p className='my-0'>Presença de sintomas significativos de {condicao.toLowerCase()}.</p>
                                                <p><Link to={`/profissionais?especialidade=${condicao}`}>Veja os psicólogos mais indicados</Link> para te ajudar a lidar com a sua {condicao.toLowerCase()}.</p>
                                            </div>
                                            <p className='fs-5 fw-bold border-top border-danger-subtle py-3 mb-0'>Sugestões de livros</p>
                                            <div className='d-flex flex-column'>
                                                <LivroSugerido imagem={livroAMenteVencendo}
                                                    titulo="A mente vencendo o humor: mude como você se sente, mudando o modo como você pensa"
                                                    autor="Christine A. Padesky, Dennis Greenberger" />
                                                <LivroSugerido imagem={livroAsCoisas}
                                                    titulo="As coisas que você só vê quando desacelera"
                                                    autor="Eckhart Tolle, Haemin Sunim" />
                                            </div>
                                        </FadeIn>
                                    </>
                                }
                                {finalQuiz && condicao == 'Depressão' && somaValores <= 11 &&
                                    <FadeIn transitionDuration='500'>
                                        <div className='fw-medium text-secondary-emphasis fs-5 mt-3 mt-sm-0'>
                                            <p className='fs-4 mb-1 fw-bold'>Sua somatória: {somaValores}</p>
                                            <p className='my-0'>Ausência de sintomas significativos de {condicao.toLowerCase()}. </p>
                                            <p className='my-0'>Mas caso deseje aprender a lidar com os sintomas relatados, não deixe de buscar um profissional.</p>
                                            <p className='mt-0 mb-3 text-center'><Link to="/profissionais">Conheça os profissionais do projeto</Link></p>
                                        </div>
                                    </FadeIn>
                                }
                                {finalQuiz && condicao == "Depressão" && somaValores >= 12 &&
                                    <>
                                        <FadeIn transitionDuration='500'>
                                            <div className='fw-medium text-secondary-emphasis fs-5 mt-3 mt-sm-0'>
                                                <p className='fw-bold fs-4 mb-1'>Sua somatória: {somaValores}</p>
                                                <p className='my-0'>Presença de sintomas significativos de {condicao.toLowerCase()}.</p>
                                                <p><Link to={`/profissionais?especialidade=${condicao}`}>Veja os psicólogos mais indicados</Link> para te ajudar a lidar com a sua {condicao.toLowerCase()}.</p>
                                            </div>                                            
                                        </FadeIn>
                                    </>
                                }
                                {finalQuiz &&
                                    <>
                                        <FadeIn transitionDuration='500'>
                                            <p className='text-center'><Link className='fs-5' onClick={() => window.location.reload()}>Refazer quiz</Link></p>
                                        </FadeIn>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
                <Footer />
            </div >
        </>
    )
}