import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import ProfissionalMiniatura from '../../components/ProfissionalMiniatura/ProfissionalMiniatura'
import Disclaimer from '../../components/Disclaimer/Disclaimer'
import Footer from '../../components/Footer/Footer'
import { AiOutlineLoading, AiOutlineLoading3Quarters } from 'react-icons/ai'
import imgBg from '../../img/img2.jpg'

import profissionais from '../../data/ProfissionaisData'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md'
import { ordenarLista } from '../../services/services'

export default function Profissionais() {
    const navigate = useNavigate();
    const [profissionaisFiltrados, setProfissionaisFiltrados] = useState([])
    const [especialidadesUnicas] = useState([]);
    const [profissionaisTeste, setProfissionaisTeste] = useState([])
    const [abordagensUnicas] = useState([]);
    const location = useLocation();
    //let { especialidade } = useParams();
    const queryParams = new URLSearchParams(location.search);
    const nome = queryParams.get('nome');
    const especialidade = queryParams.get('especialidade');
    const abordagem = queryParams.get('abordagem');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])    

    useEffect(() => {
        setProfissionaisFiltrados(profissionais.sort(ordenarLista));
        let listaFiltrada = profissionais

        if (nome) {
            //listaFiltrada = fuse.search(nome.toLowerCase()).map(result => result.item);            
            listaFiltrada = listaFiltrada.filter(x => x.Nome.toLowerCase().includes(nome.toLowerCase()));
        }
        if (especialidade) {
            listaFiltrada = listaFiltrada.filter(x => x.Especialidades.includes(especialidade))
            setTimeout(() =>
                ajustaCamposSelect("especialidade", especialidade)
                , 100)
        }
        if (abordagem) {
            listaFiltrada = listaFiltrada.filter(x => x.Abordagem.includes(abordagem))

            setTimeout(() =>
                ajustaCamposSelect("abordagem", abordagem)
                , 100)
        }

        setProfissionaisFiltrados(listaFiltrada)

        profissionais.forEach(profissional => {
            profissional.Especialidades.forEach(x => {
                if (!especialidadesUnicas.includes(x)) {
                    especialidadesUnicas.push(x);
                }
            });
        });

        profissionais.forEach(profissional => {
            profissional.Abordagem.forEach(x => {
                if (!abordagensUnicas.includes(x)) {
                    abordagensUnicas.push(x);
                }
            });
        });

        especialidadesUnicas.reverse().sort();
        abordagensUnicas.reverse().sort();
    }, [especialidade, abordagem]);


    function handleForm(e) {

        e.preventDefault();
        const formData = new FormData(e.target);
        const nome = formData.get("nome");
        let especialidade = formData.get("especialidade");
        let abordagem = formData.get("abordagem");

        setLoading(true);

        let listaFiltrada = profissionais;

        if (nome) {
            //listaFiltrada = fuse.search(nome.toLowerCase()).map(result => result.item);            
            listaFiltrada = listaFiltrada.filter(x => x.Nome.toLowerCase().includes(nome.toLowerCase()));
        }
        if (especialidade) {
            listaFiltrada = listaFiltrada.filter(x => x.Especialidades.includes(especialidade))
        }
        if (abordagem) {
            listaFiltrada = listaFiltrada.filter(x => x.Abordagem.includes(abordagem))
        }

        setProfissionaisFiltrados(listaFiltrada)
        setTimeout(function () {
            setLoading(false);
        }, 400);
    }

    function handleMostrarTodos() {
        let form = document.querySelector('#formBuscaPsicologo');
        form.reset();
        setProfissionaisFiltrados(profissionais)
        limpaSelect("especialidade");
        limpaSelect("abordagem");
    }

    function limpaSelect(id) {
        var selectElement = document.getElementById(id);

        for (var i = 0; i < selectElement.options.length; i++) {
            var option = selectElement.options[i];

            if (option.value === "") {
                option.selected = true;
                break;
            }
        }
    }

    function ajustaCamposSelect(select, valor) {
        var selectElement = document.getElementById(select);

        for (var i = 0; i < selectElement.options.length; i++) {
            var option = selectElement.options[i];

            if (option.value === valor) {
                option.selected = true;
                break;
            }
        }
    }


    return (
        <>
            <div className="min-vh-100 d-flex flex-column">
                <Navbar classNameTitulo="text-danger-emphasis" classNameItem="text-danger-emphasis" />
                <div className='bg-encontre-psi'>
                    <div className="d-flex mb-0 mt-3">
                        <Link to="/" className="d-flex border-0 m-0 p-0 pe-3 align-items-center fw-medium link-offset-2 link-underline link-underline-opacity-0 rounded-end-2 bg-branco-transparente btn-voltar">
                            <MdKeyboardDoubleArrowLeft className="flex-wrap p-0 mx-2 btn border-0" size='40px' />
                            <div className="flex-wrap text-dark fw-medium">VOLTAR</div>
                        </Link>
                    </div>
                    <div className='col-12 d-flex flex-column align-items-center mb-4 pt-3 pt-md-4 pb-md-4'>
                        <div className="mb-2 col-11 col-lg-10 col-xl-11 col-xxl-9 px-4 py-4 rounded-1 bg-branco-transparente">
                            <div className='fs-2 mb-2 fw-semibold text-center text-md-start'>Encontre seu psicólogo</div>
                            <form id="formBuscaPsicologo" method="get" onSubmit={(e) => handleForm(e)}>
                                <div className='d-flex flex-wrap align-items-md-end'>
                                    <div className='col-12 px-2'>
                                        <label htmlFor="nome" className="form-label fw-semibold fs-4 mb-1">Buscar por nome</label>
                                        <input className="form-control form-control fs-5 mb-3" type="text" placeholder="Nome" id="nome" name="nome" />
                                    </div>
                                    <div className='test col-12 col-md-4 px-2'>
                                        <label htmlFor="especialidade" className="form-label fw-semibold fs-4">Buscar por especialidade</label>
                                        <select className="form-select form-select fs-5" id="especialidade" name="especialidade">
                                            <option selected disabled value="">Buscar</option>
                                            {especialidadesUnicas.map((x, index) => (
                                                <option value={x} key={index}>{x}</option>
                                            ))}
                                        </select>
                                        <div className='d-flex'>
                                            <p className='fs-5 text-danger ms-auto limpar-select' onClick={() => limpaSelect("especialidade")}>Limpar</p>
                                        </div>
                                    </div>
                                    <div className='test col-12 col-md-4 px-2'>
                                        <label htmlFor="abordagem" className="form-label fw-semibold fs-4">Buscar por abordagem</label>
                                        <select className="form-select form-select fs-5" id="abordagem" name="abordagem">
                                            <option selected disabled value="">Buscar</option>
                                            {abordagensUnicas.map((x, index) => (
                                                <option value={x} key={index}>{x}</option>
                                            ))}
                                        </select>
                                        <div className='d-flex'>
                                            <p className='fs-5 text-danger ms-auto limpar-select' onClick={() => limpaSelect("abordagem")}>Limpar</p>
                                        </div>
                                    </div>
                                    <div className='test col-12 col-md-4 px-2'>
                                        <label style={{ opacity: '0' }} className="d-none d-md-block form-label fw-semibold fs-4">Buscar</label>
                                        <button type="submit" className="btn btn-primary fs-5 fw-semibold align-self-center col-12">Buscar</button>
                                        <div className='d-flex'>
                                            <p style={{ opacity: '0' }} className='d-none d-md-block fs-5 text-danger ms-auto limpar-select'>Limpar</p>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {profissionaisFiltrados.length > 0 && !loading &&
                    <FadeIn transitionDuration='800'>
                        <div className='d-flex flex-wrap'>
                            {profissionaisFiltrados &&
                                profissionaisFiltrados.length &&
                                profissionaisFiltrados.map((profissional, index) => (
                                    <ProfissionalMiniatura key={index} profissional={profissional} />
                                ))}
                        </div>
                    </FadeIn>
                }
                {loading &&
                    <div className='d-flex flex-fill justify-content-center mt-3 mt-sm-5 mb-5 mb-sm-0'>
                        <AiOutlineLoading color='#58151C' className='load-spinner display-1' />
                    </div>
                }
                {profissionaisFiltrados.length == 0 && !loading &&
                    <>
                        <div className='d-flex flex-fill flex-column mx-auto text-center fs-4 my-5'>
                            <p className='my-2'>Nenhum profissional encontrado com os parâmetros pesquisados</p>
                            <p className="pe-none my-0"><a href="#" className="pe-auto" onClick={handleMostrarTodos}>Clique aqui</a> para mostrar todos</p>
                        </div>
                    </>
                }
                {!loading &&
                    <Disclaimer className="mb-5" />
                }
                <Footer />
            </div >
        </>
    )
}